@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    max-width: 100%;
}

body, html {
    overflow-x: hidden;
    font-family: 'Rubik', sans-serif;
}

.row {
    margin-left: 0px;
}

.mt-15 {
    margin-top: 6rem;
}

.mb-15 {
    margin-bottom: 6rem;
}

.mt-10 {
    margin-top: 4rem;
}

input:focus,
textarea:focus {
    outline: none;
}

textarea {
    resize: none;
}

.home,.product--top
{
    position: relative;
}

.product--top
{
    background: #FEF5F1;
}


.header--container
{
    background: #F26654;
    border-radius: 80px;
    padding: 25px 80px;
    margin-top: 30px;
}

.contact--btn button,
.solar__product--btn button
{
    border: none;
    border-radius: 40px;
    background: #FFFFFF;
    padding: 16px 32px;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #F26654;
}

.nav-bar a
{
    color: rgba(255, 255, 255, 0.64);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-decoration: none;
    padding: 16px 32px;
}

.nav-bar a.active
{
    background: rgba(254, 242, 241, 0.12);
    border-radius: 60px;
    padding: 16px 32px;
    color: #FFFFFF;
    font-weight: 600 !important;
    transition:linear;
}

.home--container
{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    overflow: hidden;
    
}

/* .header__img 
{
    width: 100%;
    display: flex;
    animation: slide 20s infinite;
}

@keyframes slide{
    0%{
        transform: translateX(0);
    }
    25%{
        transform: translateX(0);
    }
    50%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(-100%);
    }
} */

.product--container
{
    padding-top: 130px;
}

.home__header h1,
.product__header h1
{
    font-style: normal;
    font-weight: 700;
    font-size: 68px;
    line-height: 120%;
    color: #1B1819;
    width: 920px;
}

.header__subheading h4,
.product__subheading h4
{
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 140%;
    color: #1B1819;
}

.about,
.solar,
.contact,
.surya,
.member,
.data,
.certificate,
.hero,
.team
{
    padding: 100px 0px;
}

.about__header h2,
.team__header h2,
.contact__header h2,
.member--header h2,
.data--header h2,
.hero__header h2
{
    font-weight: 700;
    font-size: 60px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #1B1819;
}

.about__paragraph p,
.data--paragraph p,
.hero__paragrapgh p,
.team__paragraph p
{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #1B1819;
}

.solar .container
{
    background: #F26654;
    border-radius: 8px;
    padding: 100px 40px;
}

.solar__header h3
{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}

.solar__img 
{
    position: absolute;
    bottom: -100px;
}

.contact--container
{
    padding: 40px;
    margin-top: 50px;
    background: #FFFFFF;
    border: 1px solid rgba(106, 113, 124, 0.2);
    border-radius: 16px;
    width: 590px;
    height: 221px;
}
  
.contact__name h3,
.team__name h3
{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #1C2436;
}

.contact__location h5
{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #6A717C;
}

.contact__mail a
{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: #476CF0;
}

.footer
{
    background: #1C2436;
    padding: 40px 100px;
}

.footer--container
{
    display: flex;
    justify-content: space-between;
}

.footer hr
{
    opacity: 0.1;
    border: 1px solid #FFFFFF;
    margin-top: 55px;
}

.footer__menu ul
{
    display: flex;
}

.footer__menu ul a
{
    text-decoration: none;
}

.footer__menu ul li
{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #FFFFFF;
    list-style: none;
    margin-left: 30px;
}

.footer__copyright p
{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin-top: 50px;
    color: rgba(255, 255, 255, 0.4);
}

.product--topbg
{
    background: #FEF5F1;
    width: 100%;
    min-height: 260px;
    position: absolute;
}

.surya__paragraph p
{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #1B1819;
    width: 520px;
    margin-top: 40px;
}

.surya--box
{
    border: 1px solid rgba(106, 113, 124, 0.2);
    box-shadow: -2px 4px 16px rgba(211, 213, 217, 0.2);
    border-radius: 16px;
    padding: 24px;
    margin-top: 30px;
}

.surya--topic h3
{
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #1B1819;
}

.success--paragraph p
{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: rgba(27, 24, 25, 0.64);
    margin-left: 30px;
    margin-bottom: 0;
}

.why--surya
{
    margin: 40px 0;
}

.why--surya .container
{
    background-image: url("/public/images/bg1.png");
    background-size: contain;
    padding: 95px 360px;
    width: 1240px;
    height: 350px;
    border-radius: 16px;
}

.surya--header h2
{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #FFFFFF;
    text-align: center;
}

.surya--paragraph p
{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
}

.member .container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.member--header h2
{
    width: 600px;
    text-align: center;
}

.member-box
{
    border: 1px solid rgba(105, 111, 121, 0.16);
    border-radius: 16px;
    padding: 24px;
    position: relative;
    height: 350px;
}

.member--header h3
{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    color: #1B1819;
}

.member--paragraph p
{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #1B1819;
}

.member--icon
{
    box-shadow: -2px 4px 16px rgba(211, 213, 217, 0.32);
    background: #FFFFFF;
    border-radius: 8px;
    padding: 26px;
    width: 100px;
    height: 100px;

    position: relative;
    bottom: 70px;
}

.data--paragraph p
{
    width: 520px;
}

.data--paragraph p span
{
    color: #F26654;
    cursor: pointer;
}

.certificate--box
{
    background: #FFFFFF;
    border: 1px solid rgba(105, 111, 121, 0.16);
    border-radius: 16px;
    padding: 25px 45px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.certificate--container
{
    display: flex;
    align-items: center;
}

.certificate__details
{
    margin-left: 25px;
}

.certificate__details h5
{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: rgba(27, 24, 25, 0.6);
}

.certificate__details h3
{
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 140%;
    color: #1B1819;
}

.hero 
{
    background: url("/public/images/Herobg.png");
    background-size: cover;
    padding: 150px 355px;

}

.hero .container
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.team__title
{
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #1C2436;
}

/* .team
{
    background: url("/public/images/teambg.png");
    background-size: cover;
    height: 835px;
} */

.swiper-pointer-events.swiper-vertical
{
    height: 365px;
    margin-top: 90px;
}

.team__paragraph p
{
    width: 400px;
    font-size: 16px;
}

.partners
{
    background: url("/public/images/partnersbg.png");
    background-size: contain;
    padding: 42px 84px;
    margin-top: 50px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.swiper-pagination-bullet
{
    width: 4px;
    height: 30px;
    border-radius: 0;
}

.swiper-pagination-bullet-active
{
    background: red;
    height: 30px;
    width: 4px;
}
.swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical>.swiper-pagination-bullets
{
    top: 30%;
}

.certificate .container
{
    padding: 0 100px;
}

.certificate__arrow img
{
    cursor: pointer;
    width: 16px;
    height: 32px;
}

.modal-lg, .modal-xl
{
    max-width: 100%;
}

.modal-body
{
    text-align: center;
}

.modal-header
{
    border-bottom: none;
    justify-content: end !important;
}

.modal-header svg
{
    font-size: 1.5em;
    color: #FFFFFF;
    cursor: pointer;
}

.modal-header .btn-close
{
    margin: 0;
    margin-left: 25px;
    filter: invert(1);
}

.modal-content
{
    background: none !important;
    border: none !important;
}

.prdoduct--details
{
    margin-bottom: 100px;
}

.surya--img 
{
    width: 100%;
    display: flex;
    /* animation: slide1 10s infinite; */
}

/* @keyframes slide1{
    0%{
        transform: translateX(0);
    }
    25%{
        transform: translateX(0);
    }
    40%{
        transform: translateX(-100%);
    }
    60%{
        transform: translateX(-100%);
    }
    80%{
        transform: translateX(-200%);
    }
    100%{
        transform: translateX(-200%);
    }
} */

.team__container
{
    display: flex;
    align-items: center;

    width: 400px;
    height: 223px;
    border-radius: 16px;
    padding: 24px;
}

.team__img
{
    width: 175px;
    height: 175px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 25px;
}

.team__img img
{
    width: 100%;
    height: 100%;
}

.reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease-in;
}
  
.reveal.active{
    transform: translateY(0);
    opacity: 1;
}

nav .navbar-toggler {
    display: none;
    height: min-content;
    cursor: pointer;
}

nav .navbar-toggler .bar {
    width: 22px;
    height: 3px;
    margin-bottom: 5px;
    background: #ffffff;
    border-radius: 10%;
    transition: .4s;
}

input {
    display: none;
}

input:checked ~ nav .nav-bar{
    transform: translateY(0);
    top: 75px;
}

input:checked ~ nav .navbar-toggler .bar:nth-child(1) {
    position: absolute;
    transform: rotate(45deg);
}

input:checked ~ nav .navbar-toggler .bar:nth-child(2) {
    display: none;
}

input:checked ~ nav .navbar-toggler .bar:nth-child(3) {
    transform: rotate(-45deg);
}

.success--logo svg
{
    color: #03AB5A;
    font-size: 25px;
}