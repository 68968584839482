@media (max-width: 1200px) {
    .testimonial{
        height:55%
    }
    .header--container
    {
        padding: 15px 80px;
    }

    .nav-bar a,.nav-bar a.active
    {
        padding: 12px 24px;
    }

    .home__header h1, .product__header h1
    {
        font-size: 40px;
    }

    .about, .solar, .contact, .surya, .member, .data, .certificate, .hero, .team
    {
        padding: 70px 10px;
    }

    .header__subheading h4, .product__subheading h4,
    .about__paragraph p, .data--paragraph p, .hero__paragrapgh p,
    .team__title, .surya__paragraph p, .surya--paragraph p,
    .certificate__details h3, .member--header h3
    {
        font-size: 18px;
    }

    .about__header h2, .team__header h2, .contact__header h2, .member--header h2, .data--header h2, .hero__header h2, .surya--header h2
    {
        font-size: 30px;
    }
    
    .solar__header h3, .surya--topic h3
    {
        font-size: 22px;
    }

    .contact__name h3, .team__name h3
    {
        font-size: 24px;
    }

    .team__container
    {
        height: 160px;
    }

    .why--surya .container
    {
        padding: 59px 218px;
        width: 916px;
        height: 259px;
    }

    .member-box
    {
        height: 315px;
    }

    .member--paragraph p
    {
        font-size: 14px;
    }
}

@media (max-width: 800px) {
    .testimonial{
        height:57%
    }
}
@media (max-width: 500px) {
    .testimonial{
        height:57%
    }
    .header--container
    {
        padding: 5px 20px;
        margin: 0 10px;
    }

    .contact--btn
    {
        padding-right: 1rem;
    }

    .offcanvas-header
    {
        justify-content: end;
    }

    .contact--btn button
    {
        background: none;
    }

    .navbar-nav
    {
        flex-direction: row;
    }

    .contact__mail a, .contact__location h5 {
        font-size: 16px;
    }

    .solar .container
    {
        padding: 70px 10px;
        padding-bottom: 0;
    }

    .solar__img
    {
        position: initial;
    }
    
    .solar .row
    {
        row-gap: 25px;
    }

    .footer
    {
        padding: 40px 10px;
    }
    .footer__logo
    {
        text-align: center;
    }
    .footer--container
    {
        flex-direction: column;
    }
    .footer__menu
    {
        margin-top: 35px;
    }
    .footer__menu ul
    {
        padding-left: 0;
        flex-wrap: wrap;
        justify-content: center;
    }
    .footer__menu ul li
    {
        margin: 0 28px;
        margin-bottom: 25px;
    }
    .footer hr
    {
        margin-top: 20px;
    }

    .why--surya .container
    {
        padding: 70px 35px;
        width: 320px;
        height: 350px;
    }
    .member .row,
    .surya .row
    {
        row-gap: 65px;
    }

    .surya--box
    {
        padding: 20px;
    }
    .swiper-slide .row
    {
        row-gap: 30px;
    }
    .member-box
    {
        height: 365px;
    }

    .certificate--box
    {
        padding: 25px 12px;
    }

    .certificate .container
    {
        padding: 0 10px;
    }
    .certificate__details h5,
    .team__paragraph p
    {
        font-size: 14px;
    }
    .certificate__arrow img
    {
        height: 50px;
        width: 14px;
    }
    
    .partners
    {
        flex-direction: column;
        padding: 40px 20px;
    }

    .swiper
    {
        height: 570px;
        margin: 0 50px;
        margin-top: 40px;
    }
    .swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical>.swiper-pagination-bullets
    {
        display: none;
    }

    .contact--container
    {
        padding: 20px;
        margin-top: 0px;
    }

    .product--container
    {
        padding-top: 40px;
    }
    .prdoduct--details
    {
        margin-bottom: 50px;
    }
    .success--logo svg
    {
        font-size: 25px;
    }
    .team__paragraph 
    {
        margin-top: 2rem !important;
    }
    .partners, .why--surya .container
    {
        background-size: cover;
    }
    
    .navbar-light .navbar-toggler
    {
        border: none !important;
    }
    /*.navbar-light .navbar-toggler-icon
    {
        background-image: url("/public/images/hamberger.svg");
    } */
    .team__img
    {
        width: 117px;
        height: 117px;
    }
    .success--paragraph p
    {
        margin-left: 12px;
        font-size: 16px;
        width: 220px;
    }

    /* .offcanvas
    {
        height: 150px;
        width: 345px;
        background: none;
        border-left: 0;
        transform: translateY(-100%);
    } */
    /* .offcanvas-body
    {
        background: #F26654;
        width: 320px;
        height: 102px;
        box-shadow: -2px 4px 16px rgba(211, 213, 217, 0.32);
        border-radius: 20px;
        margin-top: 20px;
    } */
    .contact--btn
    {
        display: none;
    }
    nav .navbar-toggler {
        display: grid !important;
    }

    nav .nav-bar {
        position: absolute;       
        transform: translateY(-100%);
        z-index: 10;
        transition-timing-function: linear;
        background: #F26654;
        width: 310px;
        height: 102px;
        box-shadow: -2px 4px 16px rgba(211, 213, 217, 0.32);
        border-radius: 20px;
        top: 0;
    }
    .navbar-toggler
    {
        padding-top: 10px;
    }
}